.hoverText {
  transition:
    0.35s font-weight,
    0.35s color;
}

.hoverText:hover {
  font-weight: 900;
  color: rgb(238, 242, 255);
}

/* To the right */
.hoverText:hover+.hoverText {
  font-weight: 500;
  color: #2563eb;
}

.hoverText:hover+.hoverText+.hoverText {
  font-weight: 300;
}

/* To the left */
.hoverText:has(+ .hoverText:hover) {
  font-weight: 500;
  color: rgb(199, 210, 254);
}

.hoverText:has(+ .hoverText + .hoverText:hover) {
  font-weight: 300;
}